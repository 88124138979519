







































































































/* eslint-disable lines-between-class-members */
/* eslint-disable class-methods-use-this */

import {
  Vue, Component, Watch,
} from 'vue-property-decorator';
import {
  getAuth, signOut, sendPasswordResetEmail,
} from 'firebase/auth';
import { User } from '@/types';

@Component
export default class ResetDialog extends Vue {
  dialog = false

  email = ''

  alert = false
  alertType = 'error'
  message = ''

  loading = false
  valid = false

  get value(): boolean {
    return this.$store.getters.resetDialog;
  }

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get isLoggedIn(): boolean {
    return this.currentUser.loggedIn;
  }

  get form(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }

  @Watch('value')
  onValueChanged(val: boolean) {
    const debugPrefix = 'ResetDialog: onValueChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (val) {
      // Logout se l'utente è già loggato
      if (this.isLoggedIn) {
        const auth = getAuth();
        signOut(auth).then(() => {
          console.log(`${debugPrefix} - signOut() success!`); // eslint-disable-line no-console

          this.$store.dispatch('unselectUser');
        });
      }

      this.email = '';
      this.alert = false;
      this.message = '';
      this.loading = false;
    }

    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    const debugPrefix = 'ResetDialog: onDialogChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (this.value !== val) {
      this.$store.dispatch('toggleDialog', 'reset');
    }

    // Scrolla in cima alla finestra
    // Questo metodo funziona solo per le dialog non scrollabili
    if (val) {
      this.$nextTick(() => {
        const element = document.querySelector('.v-dialog--active');
        if (element) this.$vuetify.goTo(0, { container: element as HTMLElement, duration: 0 });
      });
    }
  }

  onCancel() {
    this.dialog = false;
  }

  onSend() {
    const debugPrefix = 'LoginDialog: onSend()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (this.form.validate()) {
      this.loading = true;

      const route = this.$router.resolve({
        name: 'Map',
        query: {
          login: '1',
          email: this.email,
        },
      });

      const continueUrl = `${window.location.origin}${route.href}`;
      console.log(`${debugPrefix} - continueUrl =`, continueUrl); // eslint-disable-line no-console

      const auth = getAuth();

      sendPasswordResetEmail(auth, this.email, {
        url: continueUrl,
      })
        .then(() => {
          console.log(`${debugPrefix} - sendPasswordResetEmail() succes!`); // eslint-disable-line no-console

          this.loading = false;
          this.email = '';
          this.message = "L'email è stata inviata correttamente!";
          this.alertType = 'success';
          this.alert = true;
        }).catch((error) => {
          console.log(`${debugPrefix} - sendPasswordResetEmail() error =`, error); // eslint-disable-line no-console

          this.loading = false;

          switch (error.code) {
            case 'auth/invalid-email':
              this.message = "L'indirizzo email non è nel formato corretto";
              break;

            case 'auth/user-not-found':
              this.message = 'Non esiste un utente con questo indirizzo email';
              break;

              // case 'auth/wrong-password':
              //   this.message = 'La password non è corretta';
              //   break;

            default:
              this.message = `Errore sconosciuto (${error.code})`;
              break;
          }

          this.alertType = 'error';
          this.alert = true;
        });
    }
  }
}
